html,
body,
#root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    font-family: "Sora", sans-serif !important;
}

.ui {
    font-family: "Sora", sans-serif !important;
}

.full {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    z-index: 1000;
    box-shadow: 5px 5px 30px #888888;
}

#container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 60px 100px 60px 100px;
    z-index: 1000;
    pointer-events: none;
}

#water {
    display: block;
}

#air {
    display: block;
}

#earth {
    display: block;
}

#fire {
    display: block;
}

#bubble {
    transition: height 1s, width 1.5s, border-radius 0.5s ease-in-out;
    overflow: hidden;
    border: solid white !important;
}

.modal {
    position: "fixed";
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 15px !important;
    background-color: transparent !important;
    overflow: hidden;
}

.margined {
    margin: 20px !important;
}

.dimmer {
    /* background-color: transparent !important; */
    z-index: 0 !important;
}

#output {
    mix-blend-mode: screen;
}

.background {
    width: 100%;
    height: 100%;
    z-index: 0 !important;
    position: relative;
    background-color: #eeeeee;
}

.blurred_bg {
    backdrop-filter: blur(5px);
}

.dim {
    background-color: rgba(0, 0, 0, 0.2);
}

.blurred_btn {
    backdrop-filter: blur(10px);
}

.border_white {
    border: solid 2px white !important;
}
